import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import {paperclip} from 'react-icons-kit/ionicons/paperclip'
import {checkmark} from 'react-icons-kit/icomoon/checkmark'
import TableRow from '@material-ui/core/TableRow';
import {eye} from 'react-icons-kit/icomoon/eye'
import { Modal } from 'react-bootstrap'
import {folderUpload} from 'react-icons-kit/icomoon/folderUpload'
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { fileText2 } from 'react-icons-kit/icomoon/fileText2'
import FrLoading from '../FrLoading'
import FrSelect from '../FrSelect'
import Dialog from '@material-ui/core/Dialog';
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import api from '../../services/api'
import { ic_visibility } from 'react-icons-kit/md/ic_visibility'
import { userCheck } from 'react-icons-kit/icomoon/userCheck'
import {documents} from 'react-icons-kit/ikons/documents'
import {documentInverted} from 'react-icons-kit/entypo/documentInverted'
import Tooltip from '@material-ui/core/Tooltip';
import download from 'downloadjs'
import './index.css';
import { AirlineSeatFlatAngledSharp, UploadFile } from '@mui/icons-material';
import { getUserCode } from '../../utils/functions';

const moment = require("moment");

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#64b97d'

    },
    "& .Mui-selected:hover": {
      backgroundColor: '#64b97d'

    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FF6900',
    color: theme.palette.common.white,

  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    console.log(property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const isDateOrder = !!(props.columns[(props.columnToOrder || 0)].order)
  console.log(isDateOrder)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState(props.ascDescOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(props.columns[(props.columnToOrder || 0)][isDateOrder ? 'id' : 'order']);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [row, setRow] = useState({})
  const [fpg, setFpg] = useState(0)
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [showModalCheckin, setShowModalCheckin] = React.useState(false);
  const [widthOptions, setwidthOptions] = React.useState(100);
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const fileRef = React.useRef();
  const [file, setFile] = React.useState();
  const [comprovante, setComprovante] = React.useState();
  const [showModalUpload, setShowModalUpload] = useState(true)
  const [mov, setMov] = useState()
  const [pk_caq, setPk_caq] = useState(0)
  const [porcentagem, setPorcentagem] = React.useState(0);

  

  const handleChangeFile = (event, pk_mov) => {
    setMov(pk_mov)
    setFile(event.target.files[0]);
  };

  const handleChangeFileComprovante = (event, row) => {
    let file = event.target.files[0]
    setRow(row)
    console.log(row)
    let formData = new FormData();
    let p=0
    formData.append('file', event.target.files[0], event.target.files[0].name)
    formData.append('fk_mov', row['FK_MOV'] )
      
      
    swal.fire({
      html: <FrLoading text={`Carregando... `}/>,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.post('agenda/uploadComprovante', formData).then(r=>{
      console.log(formData)
      if(r.data == 'bacana'){
        swal.fire('Upload Concluído!', 'Arquivo carregado com sucesso.', 'success')
      }
    })
    .catch(error =>{
      swal.fire('Falha no upload!', 'Não foi possível enviar o arquivo corretamente.', 'error')
    })

    setComprovante('')
  };

  const handleDownload = (event) => {
    // api.post('prontuarios/download').then(r => {
    //   console.log(r)
    //   const url = window.URL.createObjectURL(r.data);

    // // Crie um link para iniciar o download
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = 'download.pdf'; // Defina o nome do arquivo
    //   document.body.appendChild(a);
    //   a.click();

    //   // Libere o URL temporário
    //   window.URL.revokeObjectURL(url);
    // })
    api.post('/prontuarios/download', {pk_caq}, {responseType: 'blob'}).then((response) => {
      // console.log(response)
      var blob = new Blob([response.data], {
        type: 'application/octet-stream'
      });
      // console.log(response.data)
      download(blob, 'boleto.pdf')
    })
    // const url = api.backendUrl + '/api/prontuarios/download';
    //   var link = document.createElement("a");
    //   //  link.setAttribute('download', 'teste.pdf');
    //   link.href = url;
    //   document.body.appendChild(link);
    //   link.click();
    //   link.remove();
  }


  useEffect(() => {
    setData(props.data)
    setPage(0)
    if (props.widthOptions > 0)
      setwidthOptions(props.widthOptions)
  }, [props.data])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])



  function handleDelete(e, id, table) {
    e.preventDefault()
    let comando = '/' + table + '/delete/' + id

    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {

        api.post(comando).then(r => {
          // console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }


  const visualizar = (e, id) => {
    e.preventDefault()
    window.location.href = "/intcc/prontuario/registro?codigo=" + id 
  }

  const autorizar = (e, id) => {

    e.preventDefault()
    window.location.href = "/datateck/autoriza_desvio?codigo=" + id + '&fk_usu=' + auth.user
  }


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds.filter(n => {return n >0}));
      if (props.setSelected)
        props.setSelected(newSelecteds)

      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

  };

  const handleClick = (event, name, row) => {
    console.log(row)
    if (props.mostraCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      if (props.setSelected)
        props.setSelected(newSelected)
    }
  };

  const handleClickDetail = (event, name) => {
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }

  const imprimir = (e, id) => {
    e.preventDefault()
    api.post('/desvios/relatorio?pk_dsv=' + id + '&fk_usu='+props.fk_usu,{ids: ''}, { responseType: "blob" }).then(function (response) {
      // console.log(response)
      var blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      download(blob, 'relatorio.pdf')

    });


  }

 

  useEffect(() => {
    setPorcentagem(0)
    if(file){
      
      swal.fire({
        html: <FrLoading text={`Carregando... ${0}%`}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      let formData = new FormData();
      let p=0
      formData.append('file', file, file.name)
      formData.append('fk_mov', mov )
      
      api.post(`prontuarios/upload`, formData, {
        onUploadProgress: (data) => { 
          swal.update({html: <FrLoading text={`Carregando... ${Math.round((data.loaded / data.total) * 100)}%`}/>})
          
          setPorcentagem(Math.round((data.loaded / data.total) * 100))
          // console.log(Math.round((data.loaded / data.total) * 100));
        },
      })
      .then((success) => {
        // console.log("success")
        swal.update("Upload concluído!", 'Arquivo postado com sucesso.', 'success')
        swal.fire("Upload concluído!", 'Arquivo postado com sucesso.', 'success')
        // alert("Submitted successfully");
      })
      .catch((error) => {
        // console.log(error);
        swal.fire("Falha no upload!", 'Não foi possível subir o arquivo corretamente.', 'error')
        // alert("Error happened!");
      });
      
      // console.log(file)

    }

  }, [file])

  async function mostraModalCheckin(row){
    setRow(row)
    setFpg(0)
    swal.fire({
      html: <FrLoading text={`Carregando... `}/>,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    setComboFpg(await api.getComboData('tipo_recebimento'))

    setShowModalCheckin(true)
    swal.close()
  }

  function escondeModalCheckin(){
    setShowModalCheckin(false)
  }

  function handleCheckin(e, row){
    mostraModalCheckin(row)
  }

  function confirmaModalCheckin (){
    swal.fire({
      html: <FrLoading text={`Carregando... `}/>,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    // console.log(props.refreshGrid())
    api.post('/agenda/checkin', {pk_age: row.PK_AGE, nome: row.NOME, fk_pro: row.FK_PRO, usu_logado: getUserCode(), fpg: fpg}).then(r=>{
      swal.fire('Sucesso!', 'Checkin realizado com sucesso!', 'success')
      props.refreshGrid()
      escondeModalCheckin()
    })
  }

  function podeVerProntuarioEncaminhamento (status){
    // console.log(status)
    let pode = false
    switch(status.trim()){
      case 'AGUARDANDO ACEITE': pode = true; break;
      case 'AGUARDANDO CONFIRMAÇÃO': pode = true; break;
      case 'Pendente de Reserva de Sala': pode = true; break;
      case 'CONCLUÍDO': pode = true; break;
      case 'RECUSADO': pode = false; break;
      case 'CANCELADO': pode = false; break;
    }
    return pode
  }

  function handleChange(e){
    setFpg(e.target.value)
  }


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;


  return (

    <div>
      <div>
            <Dialog aria-labelledby="simple-dialog-title" open={showModalCheckin} fullWidth={false} maxWidth={'lg'} >
              <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

                <div className="defaultHeader">

                  <h3>Confirmar Encaminhamento</h3>
                </div>
                <br />


                <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>

                  <FrSelect
                    value={fpg || ''}
                    style={{ width: 340 }}
                    name="fpg"
                    id="fpg"
                    label='Forma de Pagamento'
                    data={comboFpg}
                    onChange={handleChange}
                  />  
                  

                  

                </div>
              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button onClick={confirmaModalCheckin}> Confirmar </button>
                  <button onClick={escondeModalCheckin}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>

      <div >
        <ThemeProvider theme={theme}>
          {(data.length === 0) ? (
            <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}
              {props.mensagemSemRegistro ? props.mensagemSemRegistro : ''}

            </div>
          ) : (
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>

                      {(!props.mostraCheck) ? (<></>) :

                        <StyledTableCell padding="checkbox">
                          <FrCheckBox
                            indeterminate={selected.length > 0 && selected.length < data.length}
                            checked={data.length > 0 && selected.length === data.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </StyledTableCell>
                      }


                      {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                      {((!props.readOnly) || (props.administrar)) ? (
                        <StyledTableCell
                          style={{ minWidth: widthOptions, width: widthOptions }}
                          align={'center'}
                        >
                          Opções
                        </StyledTableCell>) : (<></>)}
                      {columns.map((column) => (
                        (column.hidden) ? <></> :
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={createSortHandler((column.order) ? column.order : column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const isItemSelected = isSelected(row[props.codeName]);
                      const isItemOpened = isOpened(row[props.codeName]);
                      const labelId = `enhanced-table-checkbox-${index}`;
                       if (props.detail) {
                        return (
                          <>
                            <TableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                              {(!props.mostraCheck) ? (<></>) :


                                <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                                  <FrCheckBox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>

                              }

                              <TableCell>
                                <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                                  {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                              </TableCell>
                              {!props.readOnly ? (
                                <TableCell>
                                  <Link to={"/intcc/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                    <Icon className="tableEdit" icon={ic_mode_edit} size={16} />
                                  </Link>
                                  <Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                </TableCell>
                              ) : (<></>)}
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.hidden)
                                  return (<></>)
                                else {
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'number' ? column.format(value) : value}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>

                          </>
                        );

                      } else {
                        return (
                          <TableRow onClick={(event) => handleClick(event, row[props.codeName], row)} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>

                            {(!props.mostraCheck) ? (<></>) :
                              <TableCell padding="checkbox">
                                <FrCheckBox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}

                                />
                              </TableCell>

                            }

                            {((!props.readOnly)) ? (
                              <TableCell style={{display: 'flex', flexDirection: 'row', minHeight: 35}}>
                                <></>
                                
                                {(props.enableEdit == 'S' && props.regName !== 'encaminhamentos' ) ? (
                                  <Tooltip title={'Editar registro'}>
                                      <Link to={"/intcc/" + props.regName + "/registro?codigo=" + row[props.codeName]} onClick={() => {console.log(row, props.codeName)}}>
                                      <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                    </Link>
                                  </Tooltip>
                                )
                                  : (<></>)}

                                {props.visualizar ? (
                                  <Link to={"/intcc/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                    <Icon className="tableEdit" icon={eye} size={16} />
                                  </Link>                             
                                  ) : (
                                  <></>
                                )}  
                              {console.log(row['FK_SED'])}
                                {(props.enableEdit == 'S' && props.regName == 'encaminhamentos' && podeVerProntuarioEncaminhamento(row['CASE']) || (props.enableEdit  && props.regName == 'agenda' && row['FK_SED'] == 3) ) ? (
                                  <Tooltip title={'Editar registro'}>
                                      <Link to={"/intcc/" + props.regName + "/registro?codigo=" + row[props.codeName]} onClick={() => {console.log(row, props.codeName)}}>
                                      <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                    </Link>
                                  </Tooltip>
                                )
                                  : (<></>)}
                                  {(props.prontuarioEletronico == 'S') ? (
                                  <Tooltip title={'Relatos'}>
                                      <Link to={"/intcc/prontuario_eletronico?buscar=true&pk_pro=" + row[props.codeName]} onClick={() => {console.log(row, props.codeName)}}>
                                      <Icon className="tableEdit" icon={documents} size={22} />
                                    </Link>
                                  </Tooltip>
                                )
                                  : (<></>)}
                                  {(props.relatoAvuslo == 'S') ? (
                                  <Tooltip title={'Relato Avulso'}>
                                      <Link to={"/intcc/prontuarios/relato_avulso/registro?pk_pro=" + row[props.codeName]} onClick={() => {console.log(row, props.codeName)}}>
                                      <Icon className="tableEdit" icon={documentInverted} size={22} />
                                    </Link>
                                  </Tooltip>
                                )
                                  : (<></>)}
                                {(props.uploadArquivos == 'S') ? (
                                  <Tooltip title={'Incluir Arquivo'}>
                                    <button onClick={() => fileRef.current.click()} style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 0, 
                                                                                            paddingRight: 0, margin: 0, border: 'none', 
                                                                                            background: 'transparent', cursor: 'default'}}>
                                      <input id="upload" name="upload" type="file" ref={fileRef} hidden onChange={(event) => handleChangeFile(event, row[props.codeName])} />  
                                      <Icon className="tableEdit" icon={paperclip} size={26} style={{cursor: 'pointer', margin:0}}/>
                                    </button>  
                                    
                                  </Tooltip>
                                )
                                  : (<></>)}

                                {(props.downloadArquivos == 'S') ? (
                                  <Tooltip title={'download Arquivo'}>
                                    <button onClick={() => handleDownload()} style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 0, 
                                                                                            paddingRight: 0, margin: 0, border: 'none', 
                                                                                            background: 'transparent', cursor: 'default'}}>
                                      
                                      <Icon className="tableEdit" icon={paperclip} size={26} style={{cursor: 'pointer', margin:0}}/>
                                    </button>  
                                    
                                  </Tooltip>
                                )
                                  : (<></>)}

                                {(props.enableDel == 'S') ? (
                                  <Tooltip title={'Excluir registro'}>
                                    <div style={{ display: 'inline' }}>
                                      <Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                    </div>
                                  </Tooltip>
                                )
                                  : (<></>)}
                                {(props.checkin == 'S' && row['FK_SED'] == 3 && row['CHECKIN'] != "S") ? (
                                  <Tooltip title={'Checkin'}>
                                      <button onClick={(e) => handleCheckin(e, row)} style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 0, 
                                                                                            paddingRight: 0, margin: 0, border: 'none', 
                                                                                            background: 'transparent', cursor: 'default'}}>
                                      
                                      <Icon className="tableEdit" icon={checkmark} size={22} style={{cursor: 'pointer', margin:0}}/>
                                    </button>  
                                  </Tooltip>
                                )
                                  : (<></>)}
                                  {(props.uploadComprovante == 'S' && row['CHECKIN'] == "S") ? (
                                    <Tooltip title={'Upload comprovante de pagamento'}>
                                        <button onClick={() => fileRef.current.click()} style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 0, 
                                                                                            paddingRight: 0, margin: 0, border: 'none', 
                                                                                            background: 'transparent', cursor: 'default'}}>
                                        <input value={comprovante} id="upload" name="upload" type="file" ref={fileRef} hidden onChange={(event) => handleChangeFileComprovante(event, row)} />  
                                        <Icon className="tableEdit" icon={folderUpload} size={26} style={{cursor: 'pointer', margin:0}}/>
                                      </button>  
                                    </Tooltip>
                                  )
                                    : (<></>)}


                              </TableCell>
                            ) : (<></>)}


                            {columns.map((column) => {
                              const value = row[column.id];
                              if (column.hidden)
                                return (<></>)
                              else {
                                if (column.image) {
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                                    </TableCell>
                                  )
                                } else {
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'number' ? column.format(value) : value}
                                    </TableCell>
                                  );
                                }
                              }
                            })}
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
